import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import LicenseForm from './LicenseForm';
import LicenseTable from './LicenseTable';
import getApiBaseUrl from './api';

function Dashboard() {
  const [licenses, setLicenses] = useState([]);
  const [showAddLicense, setShowAddLicense] = useState(false);
  const [editLicenseIndex, setEditLicenseIndex] = useState(null);
  const [selectedLicenses, setSelectedLicenses] = useState([]);
  const [username, setUsername] = useState('');
  const EMPTY_STATE = {
    customerId: '',
    count: 1,
    startDate: null,
    endDate: null,
    status: 'active',
    meta: []
  };
  const [licenseData, setLicenseData] = useState(EMPTY_STATE);
  const [initialData, setInitialData] = useState(EMPTY_STATE);

  const [filters, setFilters] = useState({
    customerId: '',
    status: ''
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(2);
  const [totalLicenses, setTotalLicenses] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/login');
    } else {
      try {
        // JWTs are  Base64URL encoded so we need to convert to Base64
        const payloadBase64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
        const payloadJson = atob(payloadBase64);
        const parsedJsonPayload = JSON.parse(payloadJson);
        setUsername(parsedJsonPayload.username);
      } catch (_) {
        setUsername('Unknown');
      }
      fetchLicenses(currentPage, pageSize, filters);
    }
  }, [history, currentPage, pageSize, filters]);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${getApiBaseUrl()}/logout`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
    } catch (_) {}
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const handleGoToCustomers = async (e) => {
    e.preventDefault();
    window.location.href = '/customers-dashboard';
  };

  const genericErrorHandler = (error, alertMessage) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      alert('Unauthorized. Please login again.');
      history.push('/login');
    } else {
      if (error.response && error.response.data.message) {
        alertMessage += "\n\n" + error.response.data.message;
      }
      alert(alertMessage);
    }
  };

  const fetchLicenses = async (page = 1, limit = 100, filters = {}) => {
    try {
      const params = {
        skip: (page - 1) * limit,
        limit: limit,
        customerId: filters.customerId || '',
        status: filters.status || ''
      };

      const response = await axios.get(`${getApiBaseUrl()}/api/v1/licenses`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params
      });

      setLicenses(response.data.licenses);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
      setPageSize(response.data.pageSize);
      setTotalLicenses(response.data.totalLicenses);
    } catch (error) {
      genericErrorHandler(error, 'Failed to fetch licenses.');
    }
  };

  const handleAddLicense = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${getApiBaseUrl()}/api/v1/licenses`, licenseData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setShowAddLicense(false);
      setLicenseData(EMPTY_STATE);
      await fetchLicenses(currentPage, pageSize, filters).catch(()=>{});
    } catch (error) {
      genericErrorHandler(error, 'Failed to add license.');
    }
  };

  const handleRemoveLicense = async (e, customerId) => {
    e.preventDefault();
    const confirmDelete = window.confirm(`Are you sure you want to delete the customer with ID: '${customerId}'?`);
    if (!confirmDelete) {
      return;
    }

    try {
      await axios.delete(`${getApiBaseUrl()}/api/v1/licenses/${customerId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      await fetchLicenses(currentPage, pageSize, filters).catch(()=>{});
    } catch (error) {
      genericErrorHandler(error, 'Failed to remove license.');
    }
  };

  const handleRemoveSelectedLicenses = async (e) => {
    e.preventDefault();
    try {
      await Promise.all(selectedLicenses.map(license =>
        axios.delete(`${getApiBaseUrl()}/api/v1/licenses/${license.customerId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
      ));
      setSelectedLicenses([]);
      await fetchLicenses(currentPage, pageSize, filters).catch(()=>{});
    } catch (error) {
      genericErrorHandler(error, 'Failed to remove selected licenses.');
    }
  };

  const handleEditLicense = (license, index) => {
    setLicenseData({
      customerId: license.customerId,
      count: license.count,
      startDate: license.startDate ? new Date(license.startDate) : null,
      endDate: license.endDate ? new Date(license.endDate) : null,
      status: license.status,
      meta: license.meta || []
    });
    setInitialData({
      ...license,
      startDate: license.startDate ? new Date(license.startDate) : null,
      endDate: license.endDate ? new Date(license.endDate) : null
    });
    setEditLicenseIndex(index);
  };

  const handleUpdateLicense = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${getApiBaseUrl()}/api/v1/licenses`, licenseData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setEditLicenseIndex(null);
      setLicenseData(EMPTY_STATE);
      await fetchLicenses(currentPage, pageSize, filters).catch(()=>{});
    } catch (error) {
      genericErrorHandler(error, 'Failed to update license.');
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [name]: value };
      return newFilters;
    });
    // reset to page 1 (common approach)
    setCurrentPage(1);
  };

const handleSelectLicense = (license) => {
  setSelectedLicenses((prevSelected) => {
    const alreadySelected = prevSelected.some(
      (sel) => sel.customerId === license.customerId
    );
    if (alreadySelected) {
      return prevSelected.filter(
        (sel) => sel.customerId !== license.customerId
      );
    } else {
      return [...prevSelected, { customerId: license.customerId }];
    }
  });
};


  const handleSelectAllLicenses = () => {
    const allSelected = licenses.every((license) =>
      selectedLicenses.some((sel) => sel.customerId === license.customerId)
    );
    
    if (allSelected) {
      // remove these from selectedLicenses
      setSelectedLicenses(prev => prev.filter(
        sel => !licenses.some(lic => lic.customerId === sel.customerId)
      ));
    } else {
      // add these to selectedLicenses
      const toAdd = licenses
        .filter(license =>!selectedLicenses.some(sel => sel.customerId === license.customerId))
        .map(license => ({ customerId: license.customerId }));

      setSelectedLicenses((prev) => [...prev, ...toAdd]);
    }
  };
  
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <button className="btn btn-secondary" onClick={handleLogout}>
          Logout
        </button>
        <h2>Welcome {username} | IAR Licenses:</h2>
        <button className="btn btn-primary" onClick={handleGoToCustomers}>
          Go to Customers Dashboard
        </button>
        <button
          className={`btn btn-primary ${showAddLicense ? 'invisible' : ''}`}
          onClick={() => {
            setLicenseData(EMPTY_STATE);
            setShowAddLicense(!showAddLicense);
          }}
        >
          Add License
        </button>
      </div>

      {showAddLicense && (
        <LicenseForm
          licenseData={licenseData}
          setLicenseData={setLicenseData}
          handleSubmit={handleAddLicense}
          setShowForm={setShowAddLicense}
          isEdit={false}
          initialData={initialData}
        />
      )}

      {editLicenseIndex !== null && (
        <LicenseForm
          licenseData={licenseData}
          setLicenseData={setLicenseData}
          handleSubmit={handleUpdateLicense}
          setShowForm={() => setEditLicenseIndex(null)}
          isEdit={true}
          initialData={initialData}
        />
      )}

      <div className="mb-3">
        <h4>Filter Licenses</h4>
        <div className="d-flex">
          <input
            type="text"
            name="customerId"
            placeholder="Customer ID"
            value={filters.customerId}
            onChange={handleFilterChange}
            className="form-control mr-2"
          />
          <input
            type="text"
            name="status"
            placeholder="Status"
            value={filters.status}
            onChange={handleFilterChange}
            className="form-control mr-2"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <button
          className="btn btn-danger"
          onClick={handleRemoveSelectedLicenses}
          disabled={selectedLicenses.length === 0}
        >
          Delete Selected
        </button>
        <span>{selectedLicenses.length} selected</span>
      </div>

      <LicenseTable
        licenses={licenses}
        handleEditLicense={handleEditLicense}
        handleRemoveLicense={handleRemoveLicense}
        selectedLicenses={selectedLicenses}
        handleSelectLicense={handleSelectLicense}
        handleSelectAllLicenses={handleSelectAllLicenses}
      />

      <div className="row mt-3">
        <div className="col-md-2 text-left">
          <label htmlFor="pageSize">Page Size: </label>
          <select id="pageSize" className="form-control d-inline w-auto ml-2" value={pageSize} onChange={handlePageSizeChange}>
            <option value={100}>100</option>
            <option value={200}>200</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
          </select>
        </div>
        <div className="col-md-8 text-center">
          <button className="btn btn-secondary mx-2" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
          <span className="mx-2">Page {currentPage} of {totalPages}</span>
          <button className="btn btn-secondary mx-2" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
        </div>
        <div className="col-md-2 text-left">
          <span>Total: {totalLicenses}</span>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
