import React from 'react';

function LicenseTable({ licenses, handleEditLicense, handleRemoveLicense, selectedLicenses, handleSelectLicense, handleSelectAllLicenses }) {
  const now = new Date();
  const allChecked = licenses.length > 0 && licenses.every((license) => selectedLicenses.some((sel) => sel.customerId === license.customerId));
  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              onChange={handleSelectAllLicenses}
              checked={allChecked}
            />
          </th>
          <th scope="col">Customer ID</th>
          <th scope="col" className="text-end">Count</th>
          <th scope="col" className="text-end">Status</th>
          <th scope="col" className="text-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        {licenses.map((license, index) => (
          <tr key={license.customerId}>
            <td>
              <input
                type="checkbox"
                checked={selectedLicenses.some((sel) => sel.customerId === license.customerId)}
                onChange={() => handleSelectLicense(license)}
              />
            </td>
            <td>{license.customerId}</td>
            <td className="text-end">{license.count}</td>
            <td className="text-end">
              <p>{license.status}</p>
              <p style={{ color: new Date(license.startDate) > now ? 'green' : 'inherit' }}>
                Start: {new Date(license.startDate).toLocaleString()}
              </p>
              <p style={{ color: new Date(license.endDate) < now ? 'red' : 'inherit' }}>
                End: {new Date(license.endDate).toLocaleString()}
              </p>
            </td>
            <td className="text-end">
              <button className="btn btn-warning btn-sm me-2" onClick={() => handleEditLicense(license, index)}>Edit</button>
              <button className="btn btn-danger btn-sm" onClick={(e) => handleRemoveLicense(e, license.customerId)}>Remove</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default LicenseTable;
